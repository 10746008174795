import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Play, Pause, SkipBack, SkipForward, Lock } from "lucide-react";

const songs = [
  { title: "Houze Party", file: "houzeparty.mp3", artist: "Daniel Houze" },
  { title: "Make it Up", file: "makeitup.mp3", artist: "Daniel Houze" },
  { title: "Mississippi High", file: "mississippihigh.mp3", artist: "Daniel Houze" },
];

const CDN_BASE_URL = "https://dwjfvvywh6i56.cloudfront.net";
const artUrl = `${CDN_BASE_URL}/art.jpg`;

const approvedNfcIds = [
  "nfcTagId1", "nfcTagId2", "nfcTagId3", "nfcTagId4",
  "nfcTagId5", "nfcTagId6", "nfcTagId7", "nfcTagId8",
  "nfcTagId9", "nfcTagId10", "nfcTagId11", "nfcTagId12",
  "nfcTagId13", "nfcTagId14", "nfcTagId15", "nfcTagId16",
  "nfcTagId17", "nfcTagId18", "nfcTagId19", "socialshare",
];

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, "0")}`;
};

const Player = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [view, setView] = useState("music");
  const [audioUrl, setAudioUrl] = useState(null);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [sessionToken, setSessionToken] = useState(null);
  const audioRef = useRef(null);

  const loadMedia = async (song) => {
    const audioDownloadUrl = `${CDN_BASE_URL}/${encodeURIComponent(song.file)}`;
    setAudioUrl(audioDownloadUrl);
    setProgress(0);
    setCurrentTime(0);
    setDuration(0);
    if (audioRef.current) {
      audioRef.current.load();
    }
  };

  const loadInitialContent = useCallback(async () => {
    try {
      await loadMedia(songs[0]);
    } catch (error) {
      console.error("Error loading initial content:", error);
    }
  }, []);

  useEffect(() => {
    const tagId = searchParams.get("tagId");
    const token = searchParams.get("token");

    if (tagId && approvedNfcIds.includes(tagId)) {
      const newToken = Math.random().toString(36).substring(2);
      setSessionToken(newToken);
      navigate(`/player?token=${newToken}`);
    } else if (token && token === sessionToken) {
      loadInitialContent();
    } else {
      setSessionToken(null);
    }
  }, [searchParams, navigate, sessionToken, loadInitialContent]);

  useEffect(() => {
    if (audioRef.current) {
      if (isPlaying) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error("Playback error:", error);
            setIsPlaying(false);
          });
        }
      } else {
        audioRef.current.pause();
      }
    }
  }, [isPlaying, audioUrl]);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const updateProgress = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime || 0;
      const duration = audioRef.current.duration || 0;
      setCurrentTime(currentTime);
      setDuration(duration);
      setProgress((currentTime / duration) * 100 || 0);
    }
  };

  const seekTo = (e) => {
    if (audioRef.current && audioRef.current.duration) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const percentage = (x / rect.width);
      audioRef.current.currentTime = percentage * audioRef.current.duration;
      setProgress(percentage * 100);
    }
  };

  const skipToNext = () => {
    const nextIndex = (currentSongIndex + 1) % songs.length;
    setCurrentSongIndex(nextIndex);
    loadMedia(songs[nextIndex]);
  };

  const skipToPrevious = () => {
    const previousIndex = (currentSongIndex - 1 + songs.length) % songs.length;
    setCurrentSongIndex(previousIndex);
    loadMedia(songs[previousIndex]);
  };

  if (!sessionToken) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-gray-50 to-white">
        <div className="bg-white/90 backdrop-blur-lg p-8 rounded-3xl shadow-xl text-center max-w-md mx-4">
          <Lock className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-900 mb-2">Access Denied</h2>
          <p className="text-gray-600">Tap your exclusive NFC card to unlock this content</p>
        </div>
      </div>
    );
  }

  const renderMusicPlayer = () => (
    <div className="flex flex-col items-center justify-center p-6">
      <div className="w-[80%] max-w-[320px] rounded-[32px] overflow-hidden mb-4">
        <img src={artUrl} alt="Album Art" className="w-full h-full object-cover" />
      </div>
      <h2 className="text-2xl font-bold text-white text-center mb-1">{songs[currentSongIndex].title}</h2>
      <p className="text-white/80 text-center">{songs[currentSongIndex].artist}</p>
      <div className="w-[90%] mt-6">
        <div className="h-1 bg-white/20 rounded-full cursor-pointer" onClick={seekTo}>
          <div className="h-full bg-white rounded-full transition-all duration-100" style={{ width: `${progress || 0}%` }} />
        </div>
        <div className="flex justify-between text-sm text-white/60 mt-2">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration - currentTime)}</span>
        </div>
      </div>
      <div className="flex items-center gap-8 mt-8">
        <button onClick={skipToPrevious} className="text-white/80 hover:text-white">
          <SkipBack size={32} />
        </button>
        <button onClick={togglePlayPause} className="w-16 h-16 bg-white text-black rounded-full flex items-center justify-center hover:scale-105 transition-transform">
          {isPlaying ? <Pause size={32} /> : <Play size={32} />}
        </button>
        <button onClick={skipToNext} className="text-white/80 hover:text-white">
          <SkipForward size={32} />
        </button>
      </div>
    </div>
  );

  const renderGallery = () => {
    const images = [
      { url: `${CDN_BASE_URL}/image1.jpg`, alt: "Daniel Houze performance 1" },
      { url: `${CDN_BASE_URL}/image2.png`, alt: "Daniel Houze performance 2" },
      { url: `${CDN_BASE_URL}/image3.jpg`, alt: "Daniel Houze performance 3" },
      { url: `${CDN_BASE_URL}/image4.jpg`, alt: "Daniel Houze performance 4" },
      { url: `${CDN_BASE_URL}/image5.jpg`, alt: "Daniel Houze performance 5" }
    ];
  
    return (
      <div className="flex flex-col h-full">
        <h1 className="text-2xl font-bold p-4 text-white text-center bg-[#483F2C] border-b border-white/10">Gallery</h1>
        <div className="flex-1 overflow-y-auto px-4 pb-40">
          <div className="columns-2 gap-4 w-full max-w-2xl mx-auto pt-4">
            {images.map((image, index) => (
              <div key={index} className="mb-4 break-inside-avoid">
                <img
                  src={image.url}
                  alt={image.alt}
                  className="w-full rounded-2xl hover:opacity-90 transition-opacity"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderBio = () => (
    <div className="flex flex-col items-center text-white p-8">
      <div className="w-[120px] h-[120px] rounded-full overflow-hidden mb-4 border-4 border-white">
        <img src={`${CDN_BASE_URL}/danielbio.jpg`} alt="Daniel Houze" className="w-full h-full object-cover" />
      </div>
      <h1 className="text-2xl font-bold mb-4 text-center">Daniel Houze</h1>
      <div className="bg-white/10 p-4 rounded-lg max-w-md text-left">
        <h2 className="text-lg font-semibold mb-2 text-center">About</h2>
        <p className="text-sm text-white/80 leading-relaxed">
          Daniel Houze is a Nashville-based singer-songwriter blending country and soul into a sound that resonates deeply with audiences.
        </p>
        <p className="text-sm text-white/80 leading-relaxed mt-4">
          Houze's singles, including "Tequila Rose" (2019), "Whiskey & Smoke" (2023), and "American Dream" (2024), showcase his blend of contemporary and traditional country music.
        </p>
        <p className="text-sm text-white/80 leading-relaxed mt-4">
          Recently signed to Lovable Losers Music Group in Nashville, Houze has been performing at notable venues like The Tin Roof. With his big smile and community spirit, Houze continues to build his brand, writing songs, and performing across the region while staying true to his country roots.
        </p>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 flex flex-col bg-[#483F2C]">
      <div className="flex-1 overflow-hidden">
        {view === "music" ? renderMusicPlayer() : view === "gallery" ? renderGallery() : renderBio()}
      </div>

      <audio
        ref={audioRef}
        src={audioUrl}
        onTimeUpdate={updateProgress}
        onEnded={skipToNext}
        preload="auto"
        className="hidden"
      />
      
      <div className="bg-[#483F2C]">
  <nav className="grid grid-cols-3 w-[350px] mx-auto pb-4 pt-4">
    <button className={`flex flex-col items-center ${view === "music" ? "text-yellow-300" : "text-white/80"}`} onClick={() => setView("music")}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m9 9 10.5-3m0 6.553v3.75a2.25 2.25 0 0 1-1.632 2.163l-1.32.377a1.803 1.803 0 1 1-.99-3.467l2.31-.66a2.25 2.25 0 0 0 1.632-2.163Zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 0 1-1.632 2.163l-1.32.377a1.803 1.803 0 0 1-.99-3.467l2.31-.66A2.25 2.25 0 0 0 9 15.553Z" />
      </svg>
      <span className="text-sm">Music</span>
    </button>
    <button className={`flex flex-col items-center ${view === "gallery" ? "text-yellow-300" : "text-white/80"}`} onClick={() => setView("gallery")}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
      </svg>
      <span className="text-sm">Gallery</span>
    </button>
    <button className={`flex flex-col items-center ${view === "bio" ? "text-yellow-300" : "text-white/80"}`} onClick={() => setView("bio")}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
      <span className="text-sm">Bio</span>
    </button>
  </nav>
  <div className="text-center text-sm text-white/60 py-2 border-t border-white/10">
    Powered by Vinyl Tap<span className="text-xs align-super">™</span>
  </div>
</div>
    </div>
  );
};

export default Player;
















